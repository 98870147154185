
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, inject } from "vue";
import moment from "moment";
import { Socket } from "socket.io-client";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "notifications-menu",
  components: {},
  setup() {
    const notifications = ref<any[]>([]);
    const store = useStore();
    const { t } = useI18n();
    const socket: Socket = inject("socket") as Socket;

    const updateData = () => {
      ApiService.get("/notifications")
        .then(({ data }) => {
          notifications.value = [];
          let nb = 0;
          for (let i = 0; i < data.notifications.length; i++) {
            nb++;
            const notification = data.notifications[i];
            if (notification.refType == "newHomeworkToValidate")
              notification.link = "/exercisesValidation/" + notification.ref;
            else if (notification.refType == "newCourseToValidate")
              notification.link = "/coursesValidation/" + notification.ref;
            else if (notification.refType == "newMeeting")
              notification.link = "/teacher/meetings";
            else if (notification.refType == "newResearch")
              notification.link = "/teacher/research/" + notification.ref;
            else if (notification.refType == "StudentPunishmentRejected")
              notification.link =
                "/student/" + notification.ref.split("#")[1] + "/punishments";
            else if (notification.refType == "newValidatedHomework")
              notification.link = "/exercise/" + notification.ref;
            else if (notification.refType == "newSubmitedHomework")
              notification.link = "/exercise/" + notification.ref;
            else if (notification.refType == "newValidatedCourse")
              notification.link = "/course/" + notification.ref;
            else notification.link = "/";

            notifications.value.push(notification);
            if (nb > 4) break;
          }
        })
        .catch((e) => console.log(e));
    };

    socket.on("StudentPunishmentRejected", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        updateData();
      }
    });

    socket.on("newSubmitedHomework", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        updateData();
      }
    });

    socket.on("newValidatedHomework", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        updateData();
      }
    });

    socket.on("newValidatedCourse", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        updateData();
      }
    });

    socket.on("newToValidation", (data) => {
      if (
        store.getters.hasAdvisorAccess &&
        data.userType == "advisor" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        updateData();
      }
    });

    socket.on("newMeeting", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        updateData();
      }
    });

    socket.on("newResearch", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        updateData();
      }
    });
    updateData();

    return {
      notifications,
      moment,
      t,
    };
  },
});
